import React, { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Box,
  Typography,
  Grid,
  Divider,
  Alert,
  Button,
  useTheme,
} from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { LoadingButton, Checkbox, TextField } from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";
import { nameToFirstName } from "@aclymatepackages/converters";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";

import ResendVerificationEmailButton from "./ResendVerificationEmailButton";
import PasswordInput from "./PasswordInput";
import OAuthButtons from "./OAuthButtons";

import Link from "../atoms/mui/Link";

import { AccountAccessContext } from "../../helpers/contexts/accountAccess";
import {
  onSignInWithEmailAndPassword,
  excludedPersonalEmailProviders,
  sendExistingCompanyAdminEmail,
  createUpdateMyAclymateAccount,
  onLoginSetSessionAndTracking,
  useReferralCookie,
} from "../../helpers/components/accountAccess";
import {
  fetchOurApi,
  fetchUserWithEmail,
  queryForExistingCompany,
  deleteOauthUser,
} from "../../helpers/utils/apiCalls";
import { auth } from "../../helpers/firebase";
import { analyticsTrack, triggerGoogleEvent } from "../../helpers/analytics";

const isEmailPersonal = (email) =>
  excludedPersonalEmailProviders.some((excludedDomain) =>
    email.includes(excludedDomain)
  );

const doesUserAlreadyExist = async (email) => {
  const existingUserData = await fetchUserWithEmail(email);
  return !!existingUserData;
};

const extractCompanyUrlFromEmail = (email) => {
  const [url] = email.split("@").reverse();
  return url;
};

const newAuthUser = ({ user, callback }) => {
  analyticsTrack("User Created", user);
  return fetchOurApi({
    path: "/onboarding/new-auth-user",
    method: "POST",
    data: user,
    callback,
  });
};

const AccountCreationInput = ({
  isNewCompanyAdmin,
  setError,
  companyId,
  individualData,
  referralPartner,
  inputData,
  setInputData,
  setVerificationEmailSent,
}) => {
  const { currentUser } = auth || {};

  const { inviteId, email: storedEmail } = isNewCompanyAdmin || {};
  const { accountCreationType } = useParams();
  const isCompanyAccountCreation = accountCreationType === "company";

  const theme = useTheme();
  const history = useHistory();

  const { referralPartner: partnerCookie } = useReferralCookie();
  const { setAccountAccessError } = useContext(AccountAccessContext) || {};
  const setErrorMsg = setAccountAccessError || setError;

  const [userLoading, setUserLoading] = useState(false);
  const [honeyPot, setHoneyPot] = useState(false);

  const { email: existingIndividualEmail = "", name: existingIndividualName } =
    individualData || {};
  const {
    email = existingIndividualEmail,
    displayName = existingIndividualName,
    password = "",
  } = inputData;

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const confirmNewCompanyAdmin = async (loginFunction) => {
    const fetchAdminUser = async () => {
      if (loginFunction) {
        return await loginFunction();
      }

      return currentUser;
    };

    const user = await fetchAdminUser();
    const { uid, email, displayName } = user;

    const loginNewAdmin = async () => {
      await user.getIdToken(true);
      onLoginSetSessionAndTracking(currentUser, `v2-companies-${companyId}`);
      return history.push("/platform/company/dashboard");
    };

    return await fetchOurApi({
      path: `/users/confirm`,
      method: "POST",
      data: {
        companyId,
        uid,
        inviteId,
        email,
        name: displayName,
      },
      callback: loginNewAdmin,
    });
  };

  const showAccountAccessSnackbar = (message) => {
    setUserLoading(false);
    return setErrorMsg(message);
  };

  const processNewAdminUserInvite = async (loginFunction) => {
    if (isNewCompanyAdmin && email !== storedEmail) {
      return showAccountAccessSnackbar(
        "The email you've input doesn't match the email of your invitation"
      );
    }

    setUserLoading(true);

    const newUserProps = {
      email: email || existingIndividualEmail,
      displayName: displayName || existingIndividualName,
      password,
    };

    return await newAuthUser({
      user: newUserProps,
      callback: () => confirmNewCompanyAdmin(loginFunction),
    });
  };

  const preCompanyAccountCreationChecks = async (
    { email, displayName, uid: existingUid },
    loginFunction
  ) => {
    if (accountCreationType === "personal") {
      return true;
    }

    if (isNewCompanyAdmin) {
      await processNewAdminUserInvite(loginFunction);
      return false;
    }

    const websiteUrl = extractCompanyUrlFromEmail(email);

    if (websiteUrl === "aclymate.com") {
      //This is important for being able to test flows internally
      return true;
    }

    const existingCompany = await queryForExistingCompany(
      "websiteUrl",
      websiteUrl
    );

    if (!existingCompany) {
      return true;
    }

    const createOrUseExistingUid = async () => {
      if (existingUid) {
        return existingUid;
      }

      return await newAuthUser({ user: inputData, callback: ({ uid }) => uid });
    };

    const uid = await createOrUseExistingUid();

    sendExistingCompanyAdminEmail(existingCompany, { uid, displayName, email });
    showAccountAccessSnackbar(
      "A company with this URL already exists. We've emailed your company administrators to let them know you want to join their account."
    );
    return false;
  };

  const passwordEmailSignIn = async (otherProps = {}) =>
    await onSignInWithEmailAndPassword({
      setErrorMsg: showAccountAccessSnackbar,
      callback: (user) => user,
      ...inputData,
      ...otherProps,
    });

  const companyAccountCallback = async (email) => {
    await fetchOurApi({
      path: "/onboarding/create-unverified-account",
      method: "POST",
      data: { email, referralPartner: partnerCookie || referralPartner },
    });

    triggerGoogleEvent("account_created");
    analyticsTrack("Company Created");
    return setVerificationEmailSent(true);
  };

  const myAccountCallback = async (email) => {
    await createUpdateMyAclymateAccount(email);
    await currentUser.getIdToken(true);
    return history.push("/platform/my/dashboard");
  };

  const completePasswordEmailAccountCreation = async () => {
    if (honeyPot) {
      return console.log("no bots allowed");
    }

    setUserLoading(true);
    const userAlreadyExists = await doesUserAlreadyExist(email);

    if (userAlreadyExists) {
      return showAccountAccessSnackbar(
        "A user with this email already exists. Try logging in instead."
      );
    }

    const doesPassPreAccountCreationChecks =
      await preCompanyAccountCreationChecks(inputData, passwordEmailSignIn);

    if (!doesPassPreAccountCreationChecks) {
      return;
    }

    await newAuthUser({ user: inputData });

    const accountCreationCallback = isCompanyAccountCreation
      ? () => companyAccountCallback(email)
      : myAccountCallback;

    return await passwordEmailSignIn({
      callback: accountCreationCallback,
    });
  };

  const completeOauthAccountCreation = async (user) => {
    const { email } = user;

    if (isCompanyAccountCreation && isEmailPersonal(email)) {
      showAccountAccessSnackbar(
        "Please use a work email to sign up for Aclymate"
      );
      return deleteOauthUser(email);
    }

    setUserLoading(true);
    const doesPassPreAccountCreationChecks =
      await preCompanyAccountCreationChecks(user);

    if (!doesPassPreAccountCreationChecks) {
      return;
    }

    if (isCompanyAccountCreation) {
      return await companyAccountCallback(email);
    }

    return myAccountCallback(email);
  };

  const isPersonalEmailError =
    isCompanyAccountCreation && isEmailPersonal(email);

  return (
    <>
      <OAuthButtons
        callback={completeOauthAccountCreation}
        setErrorMsg={setErrorMsg}
        isNewAccount
      />
      <Grid item>
        <Box position="relative">
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 13px)",
              top: "-15px",
              padding: `calc(${theme.spacing(1)} / 2)`,
              backgroundColor: "white",
            }}
          >
            <Typography variant="subtitle2" color="textSecondary">
              OR
            </Typography>
          </div>
          <Divider />
        </Box>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        {!existingIndividualName && (
          <Grid item>
            <TextField
              id="user-name-input"
              label="Your Name"
              value={displayName}
              setValue={editInputData("displayName")}
              error={displayName && incorrectNameCheck(displayName)}
              helperText={
                displayName &&
                incorrectNameCheck(displayName) &&
                "You need to have a name with less than 50 characters and it must contain at least one non-numeric character."
              }
              disabled={userLoading}
            />
          </Grid>
        )}
        {!existingIndividualEmail && (
          <Grid item>
            <TextField
              label="Email Address"
              value={email}
              setValue={editInputData("email")}
              id="email-update"
              disabled={userLoading}
            />
          </Grid>
        )}
        {isPersonalEmailError && (
          <Grid item>
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  target="_blank"
                  href="https://calendly.com/climatemike/website-inquiry-aclymate"
                  size="small"
                >
                  Contact Us
                </Button>
              }
            >
              Please use a work email to sign up for Aclymate
            </Alert>
          </Grid>
        )}
        <Grid item>
          <PasswordInput
            password={password}
            setPassword={editInputData("password")}
            helperText
            disabled={userLoading}
          />
        </Grid>
      </Grid>
      <div
        ref={(el) => el && el.style.setProperty("display", "none", "important")} //This is a weird hack because we always want this component to be hidden since it's a honey pot
      >
        <Checkbox
          name="is-remote"
          tabIndex={-1}
          value={honeyPot}
          editValue={setHoneyPot}
          label="Is this business fully remote"
          autocomplete={false}
        />
      </div>
      <Grid container item spacing={2} justifyContent="center">
        <Grid item>
          <LoadingButton
            id="new-user-submit"
            color="secondary"
            label={isNewCompanyAdmin ? "Join Account" : "Create Account"}
            disabled={
              isPersonalEmailError ||
              !emailRegExpTest(email) ||
              !displayName ||
              password.length < 12 ||
              (displayName && incorrectNameCheck(displayName))
            }
            onClick={completePasswordEmailAccountCreation}
            isLoading={userLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

const EmailConfirmationBlock = ({ email, displayName = "" }) => (
  <Grid item>
    <Box py={4}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" color="textPrimary">
            Hi {nameToFirstName(displayName)}, Welcome to Aclymate.
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            We just sent you an email with a link to the next steps. Click the
            button below if you didn't receive it.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <ResendVerificationEmailButton email={email} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

const AccountCreationForm = ({ isNewCompanyAdmin, ...otherProps }) => {
  const { email: storedEmail } = isNewCompanyAdmin || {};

  const initialUserData = storedEmail ? { email: storedEmail } : {};
  const [inputData, setInputData] = useState(initialUserData);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  return (
    <>
      {verificationEmailSent ? (
        <EmailConfirmationBlock {...inputData} />
      ) : (
        <AccountCreationInput
          isNewCompanyAdmin={isNewCompanyAdmin}
          inputData={inputData}
          setInputData={setInputData}
          setVerificationEmailSent={setVerificationEmailSent}
          {...otherProps}
        />
      )}
      <Grid item container justifyContent="center">
        <Grid item>
          <Typography variant="body1" display="inline">
            Already have an account? <Link href="/">Login instead</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default AccountCreationForm;
